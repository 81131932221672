import React from 'react'

import Layout from '../components/layout'

const ContactPage = () => (
  <Layout>
    <h1
      style={{
        fontFamily: 'georgia',
        color: 'pink',
        fontSize: '4rem',
      }}
    >
      Contact
    </h1>
    <p
      style={{
        fontFamily: 'georgia',
        color: 'pink',
        fontSize: '2rem',
        lineHeight: '1.2',
      }}
    >
      Am I pooping in your yard?
    </p>
    <p
      style={{
        fontFamily: 'georgia',
        color: 'pink',
        fontSize: '2rem',
        lineHeight: '1.2',
      }}
    >
      Do I look lost?
    </p>
    <p
      style={{
        fontFamily: 'georgia',
        color: 'pink',
        fontSize: '2rem',
        lineHeight: '1.2',
      }}
    >
      Call my people...
    </p>
    <p
      style={{
        color: 'pink',
        fontSize: '2rem',
        textAlign: 'center',
      }}
    >
      775.223.8611
    </p>
    <p
      style={{
        color: 'pink',
        fontSize: '2rem',
        textAlign: 'center',
      }}
    >
      branden.slocum@gmail.com
    </p>
  </Layout>
)

export default ContactPage
